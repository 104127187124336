// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-board-js": () => import("./../../../src/pages/board.js" /* webpackChunkName: "component---src-pages-board-js" */),
  "component---src-pages-index-en-ca-js": () => import("./../../../src/pages/index.en-CA.js" /* webpackChunkName: "component---src-pages-index-en-ca-js" */),
  "component---src-pages-index-fr-ca-js": () => import("./../../../src/pages/index.fr-CA.js" /* webpackChunkName: "component---src-pages-index-fr-ca-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-boards-js": () => import("./../../../src/templates/boards.js" /* webpackChunkName: "component---src-templates-boards-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-mlspages-js": () => import("./../../../src/templates/mlspages.js" /* webpackChunkName: "component---src-templates-mlspages-js" */),
  "component---src-templates-stats-subpages-js": () => import("./../../../src/templates/stats-subpages.js" /* webpackChunkName: "component---src-templates-stats-subpages-js" */)
}

